<template>
    <DataTable paginator :value="state.origins" :rowsPerPageOptions="[5, 10, 20]" lazy :loading="state.loading.origins"
        :currentPage="state.currentPage" :rows="state.perPage" ref="dt" dataKey="id" :totalRecords="state.totalRecords"
        :autoLayout="true" @page="onPage($event)">

        <template #header>
            <div class="flex justify-content-end">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <Button :loading="state.loading.tryingToRegister" label="Registrar Endereço" icon="pi pi-check"
                        iconPos="right" @click="state.registerAddressModal = true" />
                </span>
            </div>
        </template>

        <Column field="id" header="ID" style="width: 20%">
            <template #body="slotProps">
                {{ slotProps.data.id }}
            </template>
        </Column>
        <Column field="name" header="Nome" style="width: 20%">
            <template #body="slotProps">
                {{ slotProps.data.name }}
            </template>
        </Column>
        <Column field="ip" header="Endereço" style="width: 20%">
            <template #body="slotProps">
                {{ slotProps.data.ip }}
            </template>
        </Column>
        <Column style="width: 20%">
            <template #header>
                Ações
            </template>
            <template #body="slotProps">
                <div class="container-button">
                    <Button class="ml-2 p-button-danger" type="button" icon="pi pi-trash"
                        @click="tryToDeleteAddress(slotProps.data.ip)" />
                </div>
            </template>
        </Column>

    </DataTable>
    <Dialog v-model:visible="state.registerAddressModal" :style="{ width: '550px' }" modal class="text-center 5px"
        @update:visible="handleClose">

        <div class="p-fluid formgrid grid form mt-2">
            <div class="flex flex-column justify-content-center align-items-center w-full">


                <h1>Registar Endereço</h1>

                <div class="field col-8">
                    <label for="">Nome</label>
                    <InputText type="text" v-model="state.name" />
                </div>

                <div class="field col-8">
                    <label for="">Endereço IP</label>
                    <InputText type="text" v-model="state.ip" />
                </div>

                <div class="field col-8">
                    <Button :loading="state.loading.register" label="Registrar Endereço" icon="pi pi-check" iconPos="right"
                        @click="tryToRegisterAddress" />
                </div>

            </div>

        </div>

    </Dialog>
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import { reactive } from 'vue'
import services from '../../../services'
import useNotificationToast from '../../../composables/useNotificationToast'
import { useStore } from 'vuex'

export default {
    components: { InputText, Button, DataTable, Column, Dialog },

    props: {
        client: {
            type: Object
        },
    },

    setup(props) {
        const state = reactive({

            name: null,
            ip: null,
            origins: [],

            currentPage: 1,
            perPage: 5,
            totalRecords: 0,

            loading: {
                register: false,
                origins: false,
            },

            registerAddressModal: false,

        })

        const store = useStore()
        const { success, error } = useNotificationToast()

        loadAlowedOrigins()

        function loadAlowedOrigins() {
            state.loading.origins = true

            const token = store.getters.getToken
            const clientId = props.client.id

            services.clients.getAlowedAdresses({ id: clientId, token })
                .then((response) => {
                    state.origins = response.data
                })
                .catch(() => {
                    error("Não foi possível carregar os clientes.")
                })
                .finally(() => { state.loading.origins = false })

        }

        function tryToRegisterAddress() {

            state.loading.register = true
            const clientId = props.client.id
            const name = state.name
            const ip = state.ip
            const token = store.getters.getToken

            services.clients.registerOrigin({ id: clientId, name, ip, token }).then(() => {

                success('Endereço registrado com sucesso')
                loadAlowedOrigins()
                state.registerAddressModal = false
                state.name = null
                state.ip = null

            }).catch(() => {
                error("Não foi possível atualizar o token.")
            }).finally(() => {
                state.loading.register = false
            })
        }

        function tryToDeleteAddress(ip) {

            state.loading.delete = true
            const clientId = props.client.id
            const token = store.getters.getToken

            services.clients.deleteOrigin({ id: clientId, ip, token }).then(() => {

                success('Endereço deletado com sucesso')
                loadAlowedOrigins()
            }).catch(() => {
                error("Não foi possível deletar o endereço.")
            }).finally(() => {
                state.loading.delete = false
            })
        }

        return {
            state,
            tryToRegisterAddress,
            tryToDeleteAddress
        }

    }
}
</script>